import { Button } from '@schuettflix/planum-react';
import { Card } from './Card';
import { useConfetti } from './Confetti';
export function End() {
    useConfetti();
    return (<>
            <div className="m-auto max-w-screen-md py-8">
                <Card title="Success">
                    <h2 className="font-headline-md-strong">All done!</h2>
                    <a href="/#/" className="mt-4 block">
                        <Button type="button">Go home</Button>
                    </a>
                </Card>
            </div>
        </>);
}
