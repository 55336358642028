import store from '@/store';
import { z } from 'zod';
import { parsePhoneNumber } from './phone';
const phoneSchema = z.string().transform(value => {
    const marketCode = store.getters['user/market'].code || undefined;
    return parsePhoneNumber(value, marketCode);
});
const addressSchema = z
    .string()
    .transform(s => {
    const hasNumber = /\d+/.exec(s);
    if (hasNumber?.[0]) {
        const number = hasNumber[0];
        return { street: s.replaceAll(number, '').trim(), number };
    }
})
    .describe('Street with number');
// Refs
const organizationRefSchema = z.object({
    platformId: z.string().optional(),
    salesforceAccountId: z.string().optional(),
});
const userRefSchema = z.object({
    platformId: z.string().optional(),
    salesforceContactId: z.string().optional(),
});
const constructionProjectRefSchema = z.object({
    platformId: z.string().optional(),
    salesforceProjectId: z.string().optional(),
});
// Complete Link
const magicLinkDataSchema = z.object({
    organizations: z
        .array(z
        .object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            phone: phoneSchema.optional(),
            mobile: phoneSchema.optional(),
            fax: phoneSchema.optional(),
            address: addressSchema.optional(),
        })
            .optional(),
    })
        .transform(org => {
        if (org.extra) {
            if (org.extra.mobile) {
                org.data.mobile = org.extra.mobile;
            }
            if (org.extra.phone) {
                org.data.phone = org.extra.phone;
            }
            if (org.extra.fax) {
                org.data.fax = org.extra.fax;
            }
            if (org.extra.address) {
                if (!org.data.billingAddress) {
                    org.data.billingAddress = {};
                }
                if (typeof org.data.billingAddress === 'object' && org.data.billingAddress !== null) {
                    Object.assign(org.data.billingAddress, org.extra.address);
                }
            }
        }
        return org;
    }))
        .min(0)
        .max(1),
    users: z.array(z
        .object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            refs: z
                .object({
                organization: organizationRefSchema.optional(),
            })
                .optional(),
            mobile: phoneSchema.optional(),
        })
            .optional(),
    })
        .transform(user => {
        if (user.extra) {
            if (user.extra.mobile) {
                user.data.mobile = user.extra.mobile;
            }
        }
        return user;
    })),
    constructionProjects: z
        .array(z
        .object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            refs: z
                .object({
                responsibleEmployee: userRefSchema.optional(),
                responsibleUser: userRefSchema.optional(),
                organization: organizationRefSchema.optional(),
            })
                .optional(),
            address: addressSchema.optional(),
        })
            .optional(),
    })
        .transform(cp => {
        /**
         * In salesforce we have the deal and the project id.
         * Of course, the salesforce project is NOT the same as the construction project in the platform.
         * What in the platform is known as a project, in salesforce is known as a deal, or opportunity.
         * Here we remap that.
         */
        if (cp.data.salesforceDealId) {
            cp.data.salesforceProjectId = cp.data.salesforceDealId;
        }
        if (cp.extra?.address) {
            if (typeof cp.data.location !== 'object') {
                cp.data.location = { address: {} };
            }
            // @ts-expect-error data is not types
            Object.assign(cp.data.location.address, cp.extra.address);
        }
        return cp;
    }))
        .min(0)
        .max(1),
});
export function extractDataFromUrl() {
    const searchParams = new URLSearchParams(window.location.hash.slice(window.location.hash.indexOf('?') + 1));
    const dataAsString = searchParams.get('data');
    if (!dataAsString)
        return new Error('No data found in URL');
    try {
        const data = JSON.parse(dataAsString);
        return magicLinkDataSchema.parse(data);
    }
    catch (e) {
        console.error(e);
        return e instanceof Error ? e : new Error('Unknown error');
    }
}
