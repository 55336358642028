import { hasErrors } from '@/pages/Magic/utils/form';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/Card';
import { ErrorOverView } from '../../../components/ErrorOverview';
import { InfoPreview } from '../../../components/InfoPreview';
import { Loader } from '../../../components/Loader';
import { Section } from '../../../components/Section';
import { useAllEmployees } from '../../../hooks/api';
import { errorTranslationKeyMap } from '../form';
import { SettingsFields } from './common';
import { useDefinitionsForOrg } from './definition';
import { Fragment } from 'react';
export function SettingsPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, SettingsFields);
    const employees = useAllEmployees();
    const { definitions, sections } = useDefinitionsForOrg(values.types);
    if (employees.isLoading || definitions.isLoading) {
        return <Loader />;
    }
    const salesStaff = employees.data?.find(e => e.id === values.salesStaff?.id) ?? null;
    const accountManager = employees.data?.find(e => e.id === values.accountManager?.id) ?? null;
    return (<Card title={t('pages.organization.form.settings.headline')} onEdit={props.onEdit}>
            <ErrorOverView errors={errors} errorTranslationKeyMap={errorTranslationKeyMap}/>

            <Section>
                <InfoPreview label={t('components.organizationForm.employees.labelSalesStaff')}>
                    {salesStaff && `${salesStaff.firstName} ${salesStaff.lastName}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.employees.labelAccountManager')}>
                    {accountManager && `${accountManager.firstName} ${accountManager.lastName}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.keyAccountLabel')}>{values.keyAccount}</InfoPreview>
            </Section>

            <Section>
                <p className="font-copy-sm col-span-2 pt-2 text-subdued">
                    {t('components.organizationForm.constructionProjectSettings.headline')}
                </p>
                <InfoPreview label={t('components.organizationForm.constructionProjectSettings.costcenterOptional')}>
                    {t(values.costcenterMandatory
            ? 'pages.magic.organization.constructionProjectSettings.costcenterMandatory'
            : 'pages.magic.organization.constructionProjectSettings.costcenterOptional')}
                </InfoPreview>
            </Section>

            <Section>
                <p className="font-copy-sm col-span-2 pt-2 text-subdued">
                    {t('components.organizationForm.organizationLock.headline')}
                </p>

                <InfoPreview label={t('components.organizationForm.organizationLock.title')}>
                    {t(values.isActive
            ? 'components.magic.organization.organizationLock.lockInactive'
            : 'components.magic.organization.organizationLock.lockactive')}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.isBlocked.title')}>
                    {t(values.isBlocked
            ? 'components.magic.organization.organizationLock.organizationActive'
            : 'components.magic.organization.organizationLock.organizationInactive')}
                </InfoPreview>
            </Section>

            <hr className="my-6 border-divider"/>

            {sections
            ?.filter(s => s.isEnabled)
            .map((s, index) => {
            const activeFeatures = s.features?.filter(f => values.features?.[f.key]);
            return activeFeatures?.length > 0 ? (<Fragment key={s.key}>
                            {index !== 0 && <hr className="my-6 border-divider"/>}
                            <Section title={s.title}>
                                <ul className="flex flex-col gap-1 text-subdued">
                                    {activeFeatures.map(f => (<li key={f.key}>{f.title}</li>))}
                                </ul>
                            </Section>
                        </Fragment>) : null;
        })}
        </Card>);
}
