import { formatAddressObject } from '@/services/utils/address';
// helper function used to map state names returned by google maps API
function mapStateShortByName(name) {
    if (!name) {
        return;
    }
    switch (name.toLowerCase()) {
        case 'berlin':
            return 'BE';
        case 'nrw':
            return 'NW';
        case 'nds':
            return 'NI';
        case 'sa':
            return 'ST';
        default:
            return name.toUpperCase();
    }
}
/**
 * Reformat google place result to a usable format
 * @param {google.maps.GeocoderResult | google.maps.places.PlaceResult} place
 * @param {Object} fallbackValues
 * @return {StructuredAddress}
 */
// eslint-disable-next-line complexity
function structuredPlaceResult(place, fallbackValues = {}) {
    const fullLabel = place.formatted_address ? place.formatted_address.replace('Unnamed Road, ', '') : null;
    let hasStreetNumber = false;
    let streetComponent = null;
    const addressComponents = {};
    place.address_components?.forEach(component => {
        if (component.types.includes('street_number')) {
            hasStreetNumber = true;
        }
        if (component.types.includes('route')) {
            streetComponent = component;
        }
        if (!component.types.includes('plus_code')) {
            addressComponents[`${component.types[0]}_long`] = component.long_name;
            addressComponents[`${component.types[0]}_short`] = component.short_name;
        }
    });
    const location = {
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
    };
    const street = addressComponents.route_long === 'Unnamed Road' ? null : addressComponents.route_long;
    return {
        label: fullLabel || fallbackValues.label || null,
        state: addressComponents.administrative_area_level_1_long || fallbackValues.label || null,
        stateCode: mapStateShortByName(addressComponents.administrative_area_level_1_short || null) ||
            fallbackValues.label ||
            null,
        country: addressComponents.country_long || fallbackValues.country || null,
        countryCode: addressComponents.country_short || fallbackValues.countryCode || null,
        postalCode: addressComponents.postal_code_long || fallbackValues.postalCode || null,
        // Locality is normally the city name , but some cities like Prague are devided into political/adminstrative destricts
        // therefore google will not return a locality but a political type to that we will fallback
        city: addressComponents.locality_long ||
            addressComponents.political_long ||
            addressComponents.administrative_area_level_2_long ||
            fallbackValues.city ||
            null,
        street: street || fallbackValues.street || null,
        street_number: addressComponents.street_number_long || fallbackValues.street_number || null,
        placeId: fallbackValues.placeId || null, // must be provided later one
        location: location,
        place,
        hasStreetNumber,
        streetComponent: streetComponent || fallbackValues.streetComponent || null,
    };
}
function mapAddressFromPlaceResult(result) {
    const address = structuredPlaceResult(result);
    return {
        formatted: address.label,
        city: address.city,
        country: address.country,
        state: address.state,
        street: address.street,
        streetNumber: address.street_number,
        zipCode: address.postalCode,
        placeId: result.place_id ?? null,
    };
}
export async function getLocationForPartialAddress(address) {
    try {
        const formatted = formatAddressObject({ address });
        const sessionToken = new google.maps.places.AutocompleteSessionToken();
        const autocomplete = new google.maps.places.AutocompleteService();
        const prediction = await autocomplete.getPlacePredictions({ input: formatted, sessionToken });
        const placesService = new google.maps.places.PlacesService(document.createElement('div'));
        const place = await new Promise(res => {
            placesService.getDetails({ placeId: prediction.predictions[0].place_id }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place)
                    res(place);
            });
        });
        const latitude = place.geometry?.location?.lat();
        const longitude = place.geometry?.location?.lng();
        if (latitude === undefined || longitude === undefined) {
            return null;
        }
        return {
            address: mapAddressFromPlaceResult(place),
            coordinates: {
                latitude,
                longitude,
            },
        };
    }
    catch {
        return null;
    }
}
