import { httpClient } from '@/constructionProjects/api/client/http-client';
import { getConstructionProjectAdminById, } from '@/constructionProjects/api/projects';
import OrganizationApi from '@/services/Api/Organization';
import UserV2 from '@/services/Api/UserV2';
import { uniqBy } from 'lodash';
async function getOrgById(id) {
    if (id === undefined)
        return null;
    const org = await OrganizationApi.getOneById(id);
    return org;
}
async function getOrgBySalesForceId(salesforceAccountId) {
    const filter = { salesforceAccountId };
    if (!filter.salesforceAccountId)
        return null;
    const response = await OrganizationApi.filter(filter);
    const org = response.items.length ? response.items[0] : null;
    return org;
}
async function getUserById(id) {
    if (!id)
        return null;
    const user = await UserV2.getOneById(id);
    return user;
}
async function getUserBySalesForceId(salesforceContactId) {
    if (!salesforceContactId)
        return null;
    const filter = { salesforceContactId };
    const response = await UserV2.filter(filter);
    const user = response.items.length ? response.items[0] : null;
    return user;
}
async function getConstructionProjectById(id) {
    return id ? getConstructionProjectAdminById(id) : null;
}
async function getConstructionProjectBySalesForceId(salesForceProjectId) {
    if (!salesForceProjectId)
        return null;
    const queryParams = {
        searchTerm: salesForceProjectId,
        page: 1,
        size: 2, // We take 2, so we can check if the result is unique
        organizationList: [],
        responsibleEmployeeId: null,
        status: null,
    };
    const searchResponse = await httpClient.get('/admin/v1/construction-projects', {
        params: queryParams,
    });
    // Only if we have exactly 1 match, we have found one and unique.
    if (searchResponse.data.constructionProjects.length !== 1) {
        return null;
    }
    const projectId = searchResponse.data.constructionProjects[0].id;
    return getConstructionProjectAdminById(projectId);
}
/**
 * Preload all the referenced entities in the link data.
 * Throw errors if the referenced id have a mismatch
 */
// eslint-disable-next-line complexity
export async function getRefEntitiesFromLink(magicData) {
    const items = {
        organizations: [],
        users: [],
        constructionProjects: [],
    };
    {
        // Organizations
        const refs = [];
        for (const org of magicData.organizations) {
            const platformId = typeof org.data.id === 'string' ? org.data.id : undefined;
            const salesforceAccountId = typeof org.data.salesforceAccountId === 'string' ? org.data.salesforceAccountId : undefined;
            if (platformId || salesforceAccountId) {
                refs.push({ platformId, salesforceAccountId });
            }
        }
        for (const user of magicData.users) {
            if (user.extra?.refs?.organization) {
                refs.push(user.extra.refs.organization);
            }
        }
        for (const cp of magicData.constructionProjects) {
            if (cp.extra?.refs?.organization) {
                refs.push(cp.extra.refs.organization);
            }
        }
        const orgs = await Promise.all(refs.map(async (ref) => {
            const [byId, bySalesforceId] = await Promise.all([
                getOrgById(ref.platformId),
                getOrgBySalesForceId(ref.salesforceAccountId),
            ]);
            if (byId && bySalesforceId && byId.id !== bySalesforceId.id)
                throw new Error('Organization ID mismatch');
            return bySalesforceId || byId;
        }));
        items.organizations = uniqBy(orgs.filter((org) => org !== null), org => org.id);
    }
    {
        // Users
        const refs = [];
        for (const user of magicData.users) {
            const platformId = typeof user.data.id === 'string' ? user.data.id : undefined;
            const salesforceContactId = typeof user.data.salesforceContactId === 'string' ? user.data.salesforceContactId : undefined;
            if (platformId || salesforceContactId) {
                refs.push({ platformId, salesforceContactId });
            }
        }
        for (const cp of magicData.constructionProjects) {
            if (cp.extra?.refs?.responsibleEmployee) {
                refs.push(cp.extra.refs.responsibleEmployee);
            }
            if (cp.extra?.refs?.responsibleUser) {
                refs.push(cp.extra.refs.responsibleUser);
            }
        }
        const users = await Promise.all(refs.map(async (ref) => {
            const [byId, bySalesforceId] = await Promise.all([
                getUserById(ref.platformId),
                getUserBySalesForceId(ref.salesforceContactId),
            ]);
            if (byId && bySalesforceId && byId.id !== bySalesforceId.id)
                throw new Error('User ID mismatch');
            return bySalesforceId || byId;
        }));
        items.users = uniqBy(users.filter((user) => user !== null), user => user.id);
    }
    {
        // Construction Projects
        const refs = [];
        for (const cp of magicData.constructionProjects) {
            const platformId = typeof cp.data.id === 'string' ? cp.data.id : undefined;
            const salesforceProjectId = typeof cp.data.salesforceProjectId === 'string' ? cp.data.salesforceProjectId : undefined;
            if (platformId || salesforceProjectId) {
                refs.push({ platformId, salesforceProjectId });
            }
        }
        const cps = await Promise.all(refs.map(async (ref) => {
            const [byId, bySalesforceId] = await Promise.all([
                getConstructionProjectById(ref.platformId),
                getConstructionProjectBySalesForceId(ref.salesforceProjectId),
            ]);
            if (byId && bySalesforceId && byId.id !== bySalesforceId.id)
                throw new Error('Construction Project ID mismatch');
            return bySalesforceId || byId;
        }));
        items.constructionProjects = uniqBy(cps.filter((cp) => cp !== null), cp => cp.id);
    }
    return items;
}
/**
 * Remove items from the link data that already exists in the database.
 */
export function filterLinkDataByRefEntities(linkData, refEntities) {
    const filtered = {
        organizations: linkData.organizations.filter(linkOrg => !refEntities.organizations.some(refOrg => refOrg.id.toString() === linkOrg.data.id ||
            refOrg.salesforceAccountId === linkOrg.data.salesforceAccountId)),
        users: linkData.users.filter(linkUser => !refEntities.users.some(refUser => refUser.id.toString() === linkUser.data.id ||
            refUser.salesforceContactId === linkUser.data.salesforceContactId)),
        constructionProjects: linkData.constructionProjects.filter(linkCp => !refEntities.constructionProjects.some(refCp => refCp.id.toString() === linkCp.data.id ||
            refCp.salesforceProjectId === linkCp.data.salesforceProjectId)),
    };
    return filtered;
}
