<template>
    <div
        :class="{
            'transport-list-block--unavailable': isUnavailable,
            'transport-list-block--unsupported': isUnsupported,
            'transport-list-block--mobile': isMobile,
            'grid grid-cols-[auto_1fr] gap-px': $slots.bulkCheck,
        }"
        class="transport-list-block relative"
        data-test="transport-list-block"
        @click="$emit('click', transport)"
    >
        <!-- BULK CHECKBOX -->
        <slot name="bulkCheck" />

        <div class="transport-list-block__content">
            <!-- HEAD -->
            <header
                class="flex w-full flex-col flex-wrap gap-4 lg:flex-row lg:flex-nowrap"
                :class="{ 'pt-6': isPicked || isUnavailable }"
            >
                <div class="order-last flex shrink grow flex-wrap gap-6 overflow-hidden lg:order-first lg:flex-nowrap">
                    <template v-if="isSupplier">
                        <!-- NUMBER -->
                        <SfInfoWithContext
                            :class="computedHeaderItemClass"
                            :context="$t(`components.transportListBlock.transportNumberLabel`)"
                            data-test="transport-list-block-number"
                            important
                        >
                            <span class="whitespace-nowrap"> #{{ transport.number }} </span>
                        </SfInfoWithContext>

                        <!-- CREATED DATE -->
                        <SfInfoWithContext :class="computedHeaderItemClass" important>
                            <TransportDate
                                :label="$t(`components.transportListBlock.orderedDateLabel`)"
                                :date-from="transport.created"
                                hide-time
                            />
                        </SfInfoWithContext>

                        <!-- SERVICE DATE (PICK UP) -->
                        <SfInfoWithContext v-if="!isMobile" :class="computedHeaderItemClass" important>
                            <TransportDate
                                :label="
                                    transport.type === TRANSPORT_TYPE.PICKUP
                                        ? $t(`components.transportListBlock.timerangeLabel.pickup`)
                                        : $t(`components.transportListBlock.serviceDateLabel`)
                                "
                                v-bind="unformattedTransportServiceDate"
                            />
                        </SfInfoWithContext>

                        <!-- WEIGHING COMPLETED DATE -->
                        <SfInfoWithContext v-if="isTransportLoaded && !isMobile" :class="computedHeaderItemClass">
                            <TransportDate
                                :label="$t(`components.transportListBlock.loadedDateLabel`)"
                                :date-from="transport.weighingCompletedTimestamp"
                                :time-from="transport.weighingCompletedTimestamp"
                            />
                        </SfInfoWithContext>
                        <SfInfoWithContext v-if="isTransportUnloaded && !isMobile" :class="computedHeaderItemClass">
                            <TransportDate
                                :label="$t(`pages.transport.transportPlanning.unloadingOn`)"
                                :date-from="transport.deliveredTimestamp"
                                :time-from="transport.deliveredTimestamp"
                            />
                        </SfInfoWithContext>

                        <!-- PRICE -->
                        <SfInfoWithContext
                            v-if="isMobile && !isPlatform"
                            :class="computedHeaderItemClass"
                            class="ml-auto grow justify-end"
                        >
                            <TransportListBlockPrice :transport="transport" :context="context" />
                        </SfInfoWithContext>
                    </template>
                    <template v-else>
                        <!-- NUMBER -->
                        <SfInfoWithContext :class="computedHeaderItemClass" important>
                            <span class="whitespace-nowrap"> #{{ transport.number }} </span>
                        </SfInfoWithContext>

                        <!-- PURCHASED VEHICLE CLASS -->
                        <SfInfoWithContext :class="computedHeaderItemClass">
                            <VehicleClass :class="computedHeaderItemClass" :vehicle-name="transport.vehicleName" />
                        </SfInfoWithContext>

                        <SfInfoWithContext v-if="isMobile && !isPlatform && context" :class="computedHeaderItemClass">
                            <TransportListBlockPrice :transport="transport" :context="context" />
                        </SfInfoWithContext>

                        <!-- FREIGHT DESCRIPTION / PRODUCT NAME -->
                        <div
                            v-if="!isMobile"
                            class="flex shrink flex-col items-start justify-center gap-2 overflow-hidden truncate"
                        >
                            <TransportListBlockFreightDescription
                                :transport="transport"
                                :hide-freight-type="hideFreightType"
                                :do-not-truncate-description="isGroupedView"
                                class="lg:!flex-wrap"
                            />
                            <TransportTags :transport="transport" />
                        </div>
                    </template>
                </div>
                <div
                    class="order-first ml-auto flex w-full flex-wrap content-start items-center justify-end gap-2 lg:order-last lg:w-auto lg:shrink"
                >
                    <div class="mr-auto">
                        <slot name="bulkCheckMobile" />
                    </div>

                    <SfProjectsIcon v-if="showProjectIndicator" size="xs" />

                    <SfTag v-if="!hideTransportType" class="whitespace-nowrap">
                        {{
                            isSupplier
                                ? $t(`components.transportListBlock.supplierType.${transport.type}`)
                                : $t(`components.transportListBlock.type.${transport.type}`)
                        }}
                    </SfTag>

                    <SfTag v-if="showAccountableTag" class="whitespace-nowrap">
                        {{ $t('components.transportListBlock.type.isFactored') }}
                    </SfTag>

                    <SfTag
                        v-if="!showPreferedRemainingSecondsCounter && !isUnavailable"
                        :type="transportStatus"
                        class="whitespace-nowrap"
                    >
                        {{ statusText(transport.statusLabel) }}
                    </SfTag>

                    <TransportListBlockValidityTag v-if="showValidity" :count="remainingValidityInDays" />

                    <TransportListBlockPreferredTag
                        v-if="showPreferedRemainingSecondsCounter"
                        :transport="transport"
                        :is-unavailable="isUnavailable"
                        @preferred-counter="$emit('preferred-counter', $event)"
                    />
                </div>

                <!-- PICKED OR UNAVAILABLE LABEL -->
                <div
                    v-if="isPicked || isUnavailable"
                    class="font-copy-md-strong absolute -right-[26px] -top-[26px] py-2 pl-4 pr-18"
                    :class="{
                        'bg-surface-success text-on-success': isPicked,
                        'bg-surface-subdued text-critical': isUnavailable,
                    }"
                >
                    <template v-if="isPicked">{{ $t('pages.transportHub.pickedByCarrier') }}</template>
                    <template v-else-if="isUnavailable">{{ $t('pages.transportHub.notAvailable') }}</template>
                </div>
            </header>
            <!-- HEAD -->

            <!-- SUBHEAD -->
            <div v-if="isMobile && isSupplier" class="flex flex-wrap gap-6">
                <!-- SERVICE DATE (PICK UP) -->
                <TransportDate
                    :label="
                        transport.type === TRANSPORT_TYPE.PICKUP
                            ? $t(`components.transportListBlock.timerangeLabel.pickup`)
                            : $t(`components.transportListBlock.serviceDateLabel`)
                    "
                    v-bind="unformattedTransportServiceDate"
                />
                <!-- SERVICE DATE -->

                <!-- WEIGHING COMPLETED DATE -->
                <TransportDate
                    v-if="isTransportLoaded"
                    :label="$t(`components.transportListBlock.loadedDateLabel`)"
                    :date-from="transport.weighingCompletedTimestamp"
                    :time-from="transport.weighingCompletedTimestamp"
                />
                <TransportDate
                    v-if="isTransportUnloaded"
                    :label="$t(`pages.transport.transportPlanning.unloadingOn`)"
                    :date-from="transport.deliveredTimestamp"
                    :time-from="transport.deliveredTimestamp"
                />
                <!-- WEIGHING COMPLETED -->

                <!-- LOADING WEIGHT -->
                <SfInfoWithContext
                    v-if="isSupplier && isMobile && isTransportUnloaded && transport.unloadingWeight"
                    :context="$t('components.loadingEditor.loadedLabel')"
                    class="self-start"
                >
                    <span>{{ $n(transport.unloadingWeight / 1000, 'qty') }} t</span>
                </SfInfoWithContext>
            </div>
            <div v-else-if="isMobile">
                <TransportListBlockFreightDescription
                    :transport="transport"
                    :hide-freight-type="hideFreightType"
                    :do-not-truncate-description="isGroupedView"
                />
                <TransportTags class="mt-2" :transport="transport" />
            </div>
            <!-- SUBHEAD -->

            <!-- MAIN -->
            <div :class="{ 'transport-list-block__body--mobile': isMobile }" class="transport-list-block__body">
                <div class="relative block gap-12 lg:flex">
                    <div v-if="isSupplier && transport.product" class="lg:w-3/5">
                        <TransportListBlockSupplierProductInfo
                            :transport="transport"
                            :class="{
                                'transport-list-block__body-item transport-list-block__body-item--twice': !isMobile,
                            }"
                        />
                        <TransportTags
                            v-if="transport.type === TRANSPORT_TYPE.DISPOSAL"
                            :transport="transport"
                            class="mt-4"
                        />
                    </div>

                    <!-- SUPPLIER PRODUCT INFO -->
                    <TransportListBlockAddress
                        v-if="showAddress"
                        class="lg:w-2/5"
                        :transport="transport"
                        :is-unavailable="isUnavailable"
                        :show-shipping-dates="isMobile && transport.type !== TRANSPORT_TYPE.PICKUP"
                        :show-maximum-trafficability="!isClient"
                        show-information-buttons
                        @address-item="$emit('address-item', $event)"
                    />

                    <!-- DATES & DATE RANGES -->
                    <TransportListBlockShippingDates
                        v-if="!isMobile && !isSupplier"
                        show-loading
                        show-unloading
                        show-shipping-dates
                        :transport="transport"
                        :class="{
                            'transport-list-block__body-item transport-list-block__body-item--twice': isSupplier,
                        }"
                    />

                    <!-- RATING -->
                    <TransportListBlockFeedback v-if="!isMobile && showFeedback" :transport="transport" />

                    <!-- DISTANCE -->
                    <div v-else-if="!isMobile">
                        <span class="font-copy-sm text-subdued">
                            {{ $t('pages.transport.transportViewV2.distance') }}
                        </span>
                        <span class="font-copy-md mt-2 whitespace-nowrap">
                            {{ $n(transport.totalDistance / 1000, 'distance') }}
                            {{ $t('units.kilometer') }}&nbsp;&nbsp;&nbsp;{{
                                getFormattedDuration(transport.totalDuration)
                            }}
                        </span>
                    </div>

                    <!-- Disposal accompanying document number -->
                    <div
                        v-if="transport.product && transport.product.isDangerous && !isSupplier"
                        :class="{ 'mt-6 border-t pt-6': isMobile }"
                    >
                        <span class="font-copy-sm mb-2 text-subdued">
                            {{ $t('components.activityCards.disposal.planned.accompanyingDocumentNumberLabel') }}
                        </span>
                        <ReactBridge
                            class="mb-6"
                            :react-component="DisposalDocumentNumber"
                            :props="{
                                documentNumber: transport.accompanyingDocumentNumber,
                            }"
                        />
                    </div>

                    <!-- LOADING WEIGHT -->
                    <SfInfoWithContext
                        v-if="isSupplier && !isMobile && isTransportUnloaded && transport.unloadingWeight"
                        :context="$t('components.loadingEditor.loadedLabel')"
                        class="self-start"
                    >
                        <span>{{ $n(transport.unloadingWeight / 1000, 'qty') }} t</span>
                    </SfInfoWithContext>

                    <TransportListBlockPrice
                        v-if="!isMobile && !isPlatform"
                        :transport="transport"
                        :context="context"
                        class="transport-list-block__body-item transport-list-block__body-item--last mb-4"
                    />
                </div>

                <div class="flex w-full flex-row content-end justify-end">
                    <!-- T&C Hint-->
                    <div
                        v-if="showTermsAndConditionsHint"
                        class="transport-list-block__terms-and-conditions-hint mt-2 flex items-center pl-12 md:mt-0 md:pl-0"
                    >
                        <i18n
                            path="components.transportListBlock.termsAndPrivacyLabel"
                            tag="span"
                            class="font-copy-xs pl-2 text-subdued"
                        >
                            <a
                                :href="linkLegalTermsAndConditionsCarrier"
                                target="_blank"
                                class="font-copy-xs inline text-subdued underline"
                                place="gtc"
                                @click.stop
                                >{{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}</a
                            >
                            <a
                                :href="linkLegalDataProtectionPolicyGlobal"
                                target="_blank"
                                class="font-copy-xs inline text-subdued underline"
                                place="dpp"
                                @click.stop
                            >
                                {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                            >
                        </i18n>
                    </div>
                    <!-- VIEWS -->
                    <div
                        v-if="showViewCount"
                        class="views-block__views font-copy-md !ml-4 mb-2 !flex items-center justify-end lg:font-copy-sm lg:mb-0"
                    >
                        <ViewsIcon class="mr-2 h-6 w-6 lg:h-4 lg:w-4" />
                        <span>{{ transport.viewCount }}</span>
                    </div>

                    <!-- ACTIONS -->
                    <slot name="action" />
                </div>

                <slot v-if="!isMobile" name="notification" />
            </div>
            <!-- MAIN -->

            <template v-if="isMobile && isSupplier">
                <!-- FACTORY INFO -->
                <SfInfoWithContext
                    v-if="transport.loadingName"
                    :context="$t('components.transportListBlock.factoryLabel')"
                >
                    <span class="truncate">{{ transport.loadingName }}</span>
                </SfInfoWithContext>
                <!-- FACTORY INFO -->

                <div class="w-full">
                    <TransportListBlockCarrierInfo :transport="transport" :is-mobile="isMobile" />
                </div>
            </template>

            <!-- FOOT -->
            <!-- LOADING & UNLOADING SITE INFO -->
            <TransportListBlockSiteInformation
                v-if="showUnloadingSiteInformation && transport.loadingSiteInformation"
                :label="$t('components.transportListBlock.loadingSiteHintLabel')"
                :information="transport.loadingSiteInformation"
            />
            <TransportListBlockSiteInformation
                v-if="showUnloadingSiteInformation && transport.unloadingSiteInformation"
                :label="$t('components.transportListBlock.unloadingSiteHintLabel')"
                :information="transport.unloadingSiteInformation"
            />
            <!-- LOADING & UNLOADING SITE INFO -->

            <!-- PLANNING -->
            <div v-if="showPlannedDetails && isMobile && !isClient && !isSupplier" class="transport-list-block__meta">
                <SfInfoWithContext truncate :context="$t('components.transportListBlock.planningSupplierLabel')">
                    <span v-if="transport.vehicle">
                        {{ transport.vehicle.licensePlate }}
                    </span>
                    <span v-if="transport.driver" class="truncate whitespace-nowrap">
                        {{ transport.driver.firstName }} {{ transport.driver.lastName }}
                    </span>
                </SfInfoWithContext>
            </div>
            <div v-else-if="showPlannedDetails && !isMobile && isSupplier" class="transport-list-block__foot gap-4">
                <div
                    class="transport-list-block__meta transport-list-block__meta--primary"
                    :class="{
                        'w-[calc(100%-24px)]': transport.loadingName,
                        'w-full': !transport.loadingName,
                    }"
                >
                    <!-- FACTORY INFO -->
                    <SfInfoWithContext
                        v-if="transport.loadingName"
                        class="shrink"
                        :context="$t('components.transportListBlock.factoryLabel')"
                    >
                        <span class="truncate">{{ transport.loadingName }}</span>
                    </SfInfoWithContext>
                    <!-- FACTORY INFO -->

                    <!-- CARRIER INFO -->
                    <div
                        class="transport-list-block__meta-item transport-list-block__separator w-[calc(100%-48px)] shrink"
                    >
                        <div class="shrink truncate">
                            <TransportListBlockCarrierInfo class="pr-12" :transport="transport" :is-mobile="isMobile" />
                        </div>
                    </div>
                    <!-- CARRIER INFO -->
                </div>
                <TransportListBlockInlineActions
                    class="transport-list-block__meta transport-list-block__meta--secondary"
                    :transport="transport"
                />
            </div>
            <div v-else-if="showPlannedDetails && !isMobile" class="transport-list-block__foot gap-4">
                <div class="transport-list-block__meta transport-list-block__meta--primary">
                    <span v-if="showPlannedLabel" class="font-copy-md flex h-full">
                        {{ $t('components.transportListBlock.planningLabel') }}
                    </span>

                    <template v-if="transport.forSubcontractor && !isClient">
                        <span
                            class="transport-list-block__separator transport-list-block__separator--small font-copy-md flex h-full shrink"
                        >
                            <span class="grid grid-cols-[auto_1fr] items-center gap-2">
                                <SfPartnershipIcon size="xs" />
                                <span class="truncate">
                                    {{ $t('components.transportListBlock.subcontractor.label') }}
                                </span>
                            </span>
                        </span>
                    </template>
                    <template v-else-if="!transport.forSubcontractor">
                        <span
                            class="transport-list-block__separator transport-list-block__separator--small font-copy-md flex h-full space-x-4"
                        >
                            <span>{{ transport.vehicle.licensePlate }}</span>
                            <span>{{ transport.vehicle.vehicleClass.name }}</span>
                        </span>
                        <span
                            v-if="transport.driver"
                            class="transport-list-block__separator transport-list-block__separator--small font-copy-md flex h-full shrink truncate"
                        >
                            {{ transport.driver.firstName }} {{ transport.driver.lastName }}
                        </span>
                    </template>

                    <!-- PLANNED TIME -->
                    <TransportDate
                        v-if="plannedDeliveryTime"
                        class="transport-list-block__separator transport-list-block__separator--small flex h-full shrink truncate"
                        :date-from="plannedDeliveryTime"
                    />
                    <!-- PLANNED TIME -->
                </div>
                <TransportListBlockInlineActions
                    class="transport-list-block__meta transport-list-block__meta--secondary flex h-full items-center justify-center"
                    :transport="transport"
                />
            </div>
            <!-- PLANNING -->

            <!-- PARTICIPANTS -->
            <div v-if="visibleParticipants.length > 0" class="transport-list-block__foot">
                <div
                    :class="{ 'transport-list-block__meta--vertical': isMobile }"
                    class="transport-list-block__meta transport-list-block__meta--participants min-w-0"
                >
                    <div
                        v-if="transport.client && visibleParticipants.includes('client')"
                        class="transport-list-block__meta-item transport-list-block__meta-item--shrinkable"
                    >
                        <SfInfoWithContext
                            class="transport-list-block__meta-item-inner"
                            :context="$t('components.transportListBlock.participants.client')"
                            truncate
                        >
                            <span class="truncate">{{ transport.client.name }}</span>
                        </SfInfoWithContext>
                    </div>

                    <div
                        v-if="transportCarrier && visibleParticipants.includes('carrier')"
                        class="transport-list-block__separator transport-list-block__meta-item transport-list-block__meta-item--shrinkable"
                    >
                        <SfInfoWithContext
                            class="transport-list-block__meta-item-inner"
                            :context="$t('components.transportListBlock.participants.carrier')"
                            truncate
                        >
                            <span
                                v-if="transport.forSubcontractor && !isClient"
                                class="grid grid-cols-[auto_1fr] items-center gap-2"
                            >
                                <SfPartnershipIcon size="xs" />
                                <span class="truncate">
                                    {{
                                        $t('components.transportListBlock.subcontractor.prefix', {
                                            companyName: transportCarrier,
                                        })
                                    }}
                                </span>
                            </span>
                            <span v-else class="truncate">
                                {{ transportCarrier }}
                            </span>
                        </SfInfoWithContext>
                    </div>

                    <div
                        v-if="transport.supplier && visibleParticipants.includes('supplier')"
                        class="transport-list-block__separator transport-list-block__meta-item transport-list-block__meta-item--shrinkable"
                    >
                        <SfInfoWithContext
                            class="transport-list-block__meta-item-inner"
                            :context="$t('components.transportListBlock.participants.supplier')"
                            truncate
                        >
                            <span class="truncate">{{ transport.supplier.name }}</span>
                        </SfInfoWithContext>
                    </div>
                </div>
            </div>
            <!-- PARTICIPANTS -->
            <!-- FOOT -->
        </div>
    </div>
</template>

<script>
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import { mapGetters } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import { getFormattedDuration } from '@/services/utils/date';
import {
    statusText,
    transportProgress,
    isStatusPositive,
    isStatusNeutral,
    isStatusNegative,
    statusIsAfter,
    getRemainingValidityInDays,
} from '@/services/utils/transport';
import { isDisposalTransport } from '@/services/Disposal/utils';
import { isAccountable } from '@/components/List/utils/isAccountable';

import TransportDate from './partials/TransportDate';

import TransportListBlockAddress from './TransportListBlock/TransportListBlockAddress';
import TransportListBlockCarrierInfo from './TransportListBlock/TransportListBlockCarrierInfo';
import TransportListBlockFeedback from './TransportListBlock/TransportListBlockFeedback';
import TransportListBlockFreightDescription from './TransportListBlock/TransportListBlockFreightDescription';
import TransportListBlockInlineActions from './TransportListBlock/TransportListBlockInlineActions';
import TransportListBlockPreferredTag from './TransportListBlock/TransportListBlockPreferredTag';
import TransportListBlockPrice from './TransportListBlock/TransportListBlockPrice';
import TransportListBlockShippingDates from './TransportListBlock/TransportListBlockShippingDates';
import TransportListBlockSupplierProductInfo from './TransportListBlock/TransportListBlockSupplierProductInfo';
import TransportListBlockSiteInformation from './TransportListBlock/TransportListBlockSiteInformation';
import TransportListBlockValidityTag from '@/components/Transport/TransportListBlock/TransportListBlockValidityTag';

import ViewsIcon from '@/assets/icons/regular/eye.svg';

import { SfTag, SfProjectsIcon, SfInfoWithContext, SfPartnershipIcon } from '@schuettflix/vue-components';

import VehicleClass from './partials/VehicleClass';
import TransportTags from './partials/TransportTags';
import DisposalDocumentNumber from '@/_components/DisposalDocumentNumber/DisposalDocumentNumber';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlock',
    components: {
        ReactBridge,
        TransportDate,

        TransportListBlockAddress,
        TransportListBlockCarrierInfo,
        TransportListBlockFeedback,
        TransportListBlockFreightDescription,
        TransportListBlockInlineActions,
        TransportListBlockPreferredTag,
        TransportListBlockPrice,
        TransportListBlockShippingDates,
        TransportListBlockSupplierProductInfo,
        TransportListBlockSiteInformation,
        TransportListBlockValidityTag,

        ViewsIcon,

        // components
        SfTag,
        SfProjectsIcon,
        SfInfoWithContext,
        SfPartnershipIcon,

        // transport partials
        VehicleClass,
        TransportTags,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        isUnavailable: {
            type: Boolean,
            default: false,
        },
        isPicked: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        context: {
            type: String,
            default: 'client',
        },
        hideProjectIndicator: {
            type: Boolean,
            default: false,
        },
        hideTransportType: {
            type: Boolean,
            default: false,
        },
        hideFreightType: {
            type: Boolean,
            default: false,
        },
        isGroupedView: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const { transport, context, hideProjectIndicator } = this;

        const isPlatform = context === 'platform';
        const isCarrier = context === 'carrier';
        const isClient = context === 'client';
        const isSupplier = context === 'supplier';

        const transportStatus = isStatusNegative(transport.status)
            ? 'error'
            : isStatusPositive(context, transport.status)
            ? 'positive'
            : isStatusNeutral(transport.status)
            ? 'warning'
            : 'default';

        const isTransportLoaded =
            !isDisposalTransport(transport) &&
            statusIsAfter(transport.status, 'loaded') &&
            !!transport.weighingCompletedTimestamp;
        const isTransportUnloaded =
            isDisposalTransport(transport) &&
            statusIsAfter(transport.status, 'delivered', transport.type) &&
            !!transport.deliveredTimestamp;
        const remainingValidityInDays = getRemainingValidityInDays(transport);

        const showViewCount = transport?.viewCount && transport?.status === 'new';
        const showTermsAndConditionsHint = isCarrier && ['new', 'assigned'].includes(transport?.status);

        const showAddress = !isSupplier;
        const showFeedback = transport.type === TRANSPORT_TYPE.PICKUP || isSupplier;
        const showPlannedLabel =
            transport.type !== TRANSPORT_TYPE.PICKUP &&
            !isSupplier &&
            isClient &&
            transport.type === TRANSPORT_TYPE.DELIVERY;
        const showUnloadingSiteInformation = !isSupplier && transport.type !== TRANSPORT_TYPE.SHIPMENT;
        const showProjectIndicator = transport?.projectPositionId && !hideProjectIndicator;

        const canHavePlatformOrderManagement = this.$can('havePlatformOrderManagement') || isClient;
        const showValidity =
            transport.status === 'new' && remainingValidityInDays >= 1 && canHavePlatformOrderManagement;

        const isUnsupported = (() => {
            const { type } = transport;

            if (type !== TRANSPORT_TYPE.PICKUP && isCarrier) {
                return false;
            }

            if (
                [TRANSPORT_TYPE.DELIVERY, TRANSPORT_TYPE.PICKUP, TRANSPORT_TYPE.DISPOSAL].includes(type) &&
                isSupplier
            ) {
                return false;
            }

            return !(isClient || isPlatform);
        })();

        const visibleParticipants = (() => {
            if (isCarrier || isSupplier) {
                return [];
            }

            if (isClient) {
                switch (transport.type) {
                    case TRANSPORT_TYPE.PICKUP:
                        return ['supplier'];
                    case TRANSPORT_TYPE.DELIVERY:
                    case TRANSPORT_TYPE.DISPOSAL:
                        return ['carrier', 'supplier'];
                    case TRANSPORT_TYPE.SHIPMENT:
                        return transport.status === 'new' ? [] : ['carrier'];
                }
            }

            return ['client', 'supplier', 'carrier'];
        })();

        const transportCarrier = (() => {
            if (transport.type === TRANSPORT_TYPE.PICKUP) {
                return transport.client.name;
            }

            return (transport.carrier && transport.carrier.name) || null;
        })();

        const plannedDeliveryTime = (() => {
            // do not show any time to the supplier
            if (isSupplier) {
                return null;
            }

            if (
                (transport.forSubcontractor && !statusIsAfter(transport.status, 'planned_subcontractor')) ||
                !statusIsAfter(transport.status, 'planned')
            ) {
                return null;
            }

            if (transport.shippingMethod === 'flix') {
                return transport.shippingDate;
            }

            if (isDisposalTransport(this.transport)) {
                return transport.plannedLoadingTime;
            }

            return transport.plannedDeliveryTime;
        })();

        const showPlannedDetails = (() => {
            if (isSupplier) {
                return true;
            }

            return (
                (transport.vehicle || transport.forSubcontractor) &&
                [
                    'planned',
                    'planned_subcontractor',
                    'started',
                    'checked_in',
                    'checked_in_loading',
                    'ready_to_deliver',
                    'in_transit',
                    'destination_waiting',
                    'delivered',
                ].includes(transport.status)
            );
        })();

        const unformattedTransportServiceDate = (() => {
            if (transport.shippingMethod === 'flix') return { dateFrom: transport.shippingDate };

            if (transport.type === TRANSPORT_TYPE.PICKUP)
                return {
                    dateFrom: transport.expireDate || transport.lineItemGroupExpireTimestamp || null,
                };

            return {
                dateFrom: transport.shippingWindowStart,
                dateTo: transport.shippingWindowEnd,
            };
        })();

        return {
            DisposalDocumentNumber,
            canHavePlatformOrderManagement,
            isPlatform,
            isCarrier,
            isClient,
            isSupplier,
            isUnsupported,
            showProjectIndicator,
            visibleParticipants,
            showViewCount,
            showAddress,
            showFeedback,
            showPlannedLabel,
            showUnloadingSiteInformation,
            transportCarrier,
            plannedDeliveryTime,
            isTransportLoaded,
            isTransportUnloaded,
            showPlannedDetails,
            unformattedTransportServiceDate,
            showValidity,
            remainingValidityInDays,
            transportStatus,
            showTermsAndConditionsHint,
        };
    },
    computed: {
        ...mapGetters('platform', ['linkLegalDataProtectionPolicyGlobal', 'linkLegalTermsAndConditionsCarrier']),

        TRANSPORT_TYPE() {
            return TRANSPORT_TYPE;
        },

        showPreferedRemainingSecondsCounter() {
            return (
                this.transport.__preferedRemainingSeconds !== undefined &&
                this.transport.status === 'new' &&
                !this.isPicked &&
                !this.isUnavailable
            );
        },

        computedHeaderItemClass() {
            return [
                '!gap-0',
                "after:block after:h-full after:w-6 after:border-r after:border-divider after:content-['']",
                'last:after:hidden',
            ];
        },

        showAccountableTag() {
            return isAccountable(this.transport);
        },
    },
    methods: {
        revertLocalizedValue,
        transportProgress,
        getFormattedDuration,
        isStatusPositive,
        isStatusNeutral,
        isStatusNegative,

        statusText(status) {
            return statusText(this.context, status);
        },
    },
};
</script>

<style lang="scss" scoped>
.transport-list-block__content {
    > * + * {
        margin-top: 24px;
        border-top: 1px solid $color-littleDarkerThanMediumGrey;
        padding-top: 24px;
    }
}

.transport-list-block--unsupported {
    position: relative;

    &::before {
        content: 'UNSUPPORTED';
        display: flex;
        position: absolute;
        left: -18px;
        top: -18px;
        right: -18px;
        bottom: -18px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        z-index: 10;
        letter-spacing: 1em;
    }
}

.transport-list-block--unavailable {
    color: $color-grey;
}

.transport-list-block__separator {
    &::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 44px;
        background-color: $color-littleDarkerThanMediumGrey;
        vertical-align: middle;
        margin-right: 24px;
        margin-left: 24px;
        // TODO: find a way to display seperator properly on all devices
        // transform: rotate(360deg);
    }

    &--small {
        &::before {
            height: 24px;
        }
    }
}

.transport-list-block__meta-item--shrinkable {
    max-width: 100%;
    min-width: 0;
}

.transport-list-block__foot {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    white-space: nowrap;

    .transport-list-block__meta--secondary {
        padding-left: 24px;
        margin-inline-start: auto;
    }
}

.transport-list-block__meta {
    display: flex;
    align-items: flex-start;

    > * {
        display: flex;
        align-items: center;
    }

    > *:first-child::before {
        display: none;
    }
}

.transport-list-block__meta--secondary {
    justify-content: flex-end;
    justify-self: flex-end;

    > * + * {
        margin-left: 16px;
    }
}

.transport-list-block__meta--vertical {
    flex-flow: column;
    width: 100%;
    align-items: flex-start;

    .transport-list-block__meta-item {
        width: 100%;
    }

    .transport-list-block__separator:not(:first-child) {
        border-top: 1px solid $color-littleDarkerThanMediumGrey;
        padding-top: 24px;
        margin-top: 24px;

        &::before {
            display: none;
        }
    }
}

.transport-list-block__body {
    > *:last-child {
        text-align: right;
    }
}

.transport-list-block__body-item--centered {
    align-self: center;
}

.transport-list-block__body-item--last {
    justify-self: flex-end;
    text-align: right;
    margin-left: auto !important;
}

.transport-list-block__body-item--twice {
    grid-column: span 2;
}

.transport-list-block__meta-item-inner {
    width: 100%;
}

.transport-list-block__terms-and-conditions-hint {
    @media screen and (min-width: $screen-md) {
        max-width: 210px;
    }
}
</style>
