export const DELIVERY = {
    states: {
        NEW: 'new',
        CANCELLED: 'cancelled',
        ASSIGNED: 'assigned',
        PLANNED: 'planned',
        STARTED: 'started',
        CHECKED_IN: 'checked_in',
        LOADED: 'loaded',
        READY_TO_DELIVER: 'ready_to_deliver',
        IN_TRANSIT: 'in_transit',
        DESTINATION_WAITING: 'destination_waiting',
        UNLOADING_DELAYED: 'unloading_delayed',
        CONFIRMATION_PENDING: 'confirmation_pending',
        FAILED: 'failed',
        DELIVERED: 'delivered',
    },
};
export const PICKUP = {
    states: {
        STARTED: 'new',
        CHECKED_IN: 'checked_in',
        PICKUP_LOADED: 'pickup_loaded',
        DELIVERED: 'delivered',
        FAILED: 'failed',
    },
};
export const SHIPMENT = {
    states: {
        NEW: 'new',
        CANCELLED: 'cancelled',
        ASSIGNED: 'assigned',
        PLANNED: 'planned',
        STARTED: 'started',
        CHECKED_IN: 'checked_in',
        READY_TO_DELIVER: 'ready_to_deliver',
        IN_TRANSIT: 'in_transit',
        DESTINATION_WAITING: 'destination_waiting',
        FAILED: 'failed',
        DELIVERED: 'delivered',
    },
};
export const DISPOSAL = {
    states: {
        CREATED: 'created',
        ASSIGNED: 'assigned',
        PLANNED: 'planned',
        STARTED: 'started',
        CHECKED_IN_LOADING: 'checked_in_loading',
        DELIVERED: 'delivered',
        FAILED: 'failed',
    },
};
