import { EnhancedTooltip, Table } from '@schuettflix/react-components';
import { TRANSPORT_STATE_BY_STATUS } from '@/pages/Order/TransportList/TransportTypes';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
export const TransportListDesktop = ({ transports }) => {
    const { t, i18n } = useTranslation();
    const formatNumber = (value) => {
        return value.toLocaleString(i18n.resolvedLanguage);
    };
    return (<Table columns={[
            { isRowHeader: true, textValue: t('order.transportList.transportNumber') },
            { isRowHeader: true, textValue: t('order.transportList.quantity') },
            { isRowHeader: true, textValue: t('order.transportList.serviceDate') },
            { isRowHeader: true, textValue: t('order.transportList.reference') },
            { isRowHeader: true, textValue: t('order.transportList.carrier') },
            { isRowHeader: true, textValue: t('order.transportList.state') },
        ]} placeholder={t('order.transportList.emptyListPlaceholder')} enableHeader locale={i18n.language} size="md">
            {transports.map(transport => (<Table.Row key={transport.id}>
                    <Table.Cell>
                        <Table.Item.Number>
                            <a className="cursor-pointer underline" href={`#/order-management/transport-hub/detail/${transport.id}`}>
                                {transport.transportNumber}
                            </a>
                        </Table.Item.Number>
                    </Table.Cell>
                    <Table.Cell>
                        <Table.Item.Text className={clsx('whitespace-nowrap', {
                'font-bold text-success': transport.status === 'fulfilled',
            })}>
                            {formatNumber(transport.quantity)} {transport.unit}
                        </Table.Item.Text>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap">
                        <>
                            {transport.serviceDate?.type === 'period' && (<Table.Item.DateRange dateFrom={new Date(transport.serviceDate.from)} dateTo={new Date(transport.serviceDate.to)}/>)}
                            {transport.serviceDate?.type === 'timePoint' && (<Table.Item.Date date={new Date(transport.serviceDate.at)}/>)}
                            {!transport.serviceDate && `---`}
                        </>
                    </Table.Cell>
                    <Table.Cell>
                        <Table.Item.Text>{transport.reference}</Table.Item.Text>
                    </Table.Cell>
                    <Table.Cell truncate className="min-w-[250px]">
                        <Table.Item.Text>
                            {transport.carrierName.length > 32 ? (<EnhancedTooltip delayOnClose={0} delayOnOpen={0} trigger={<span>{transport.carrierName}</span>} position="top" size="sm">
                                    {transport.carrierName}
                                </EnhancedTooltip>) : (transport.carrierName)}
                        </Table.Item.Text>
                    </Table.Cell>
                    <Table.Cell>
                        <Table.Item.Status status={TRANSPORT_STATE_BY_STATUS[transport.status]}>
                            {t(`order.transportList.status.${TRANSPORT_STATE_BY_STATUS[transport.status]}`)}
                        </Table.Item.Status>
                    </Table.Cell>
                </Table.Row>))}
        </Table>);
};
