export function mapToTransportListItems(transportResponseData) {
    return (transportResponseData?.items ?? []).map(transportItem => {
        const status = transportItem.status;
        return {
            id: transportItem.transportId,
            transportNumber: transportItem.transportNumber.replace(`${transportItem.orderNumber}-`, ''),
            quantity: transportItem.cargo.payload?.value ?? transportItem.cargo.requestedPayload?.value,
            unit: transportItem.cargo.payload?.unit ?? transportItem.cargo.requestedPayload?.unit,
            reference: transportItem.cargo.weighingNoteNumber ?? '---',
            carrierName: transportItem.carrier?.name ?? '---',
            status: status,
            serviceDate: getTransportServiceDate({ ...transportItem, status }),
        };
    });
}
function getTransportServiceDate(transportResponseItem) {
    switch (transportResponseItem.status) {
        case 'inTransit':
        case 'transitCompleted':
        case 'planned': {
            const serviceDate = transportResponseItem.locations.find(location => location.planning)?.planning
                ?.plannedTime;
            if (!serviceDate) {
                throw new Error(`plannedTime date required in ${transportResponseItem.status} transport Status`);
            }
            return {
                type: 'timePoint',
                at: serviceDate,
            };
        }
        case 'new':
        case 'assigned':
        case 'fulfilled': // TODO: change 'fulfilled' state to return the correct serviceDate when added to the useTransportsScopedByOrder Hook
            return transportResponseItem.locations.find(location => location.requestedShippingSchedule)
                ?.requestedShippingSchedule;
        default:
            throw new Error('Invalid Transport Status');
    }
}
