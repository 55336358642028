import { Button } from '@schuettflix/planum-react';
import { Card } from './Card';
import { useTranslation } from 'react-i18next';
export function Noop() {
    const { t } = useTranslation();
    return (<>
            <div className="m-auto max-w-screen-md py-8">
                <Card title={t('pages.magic.components.noopCard.title')}>
                    <p>{t('pages.magic.components.noopCard.text')}</p>
                    <a href="/#/" className="mt-4 block">
                        <Button type="button">{t('pages.magic.components.noopCard.button.title')}</Button>
                    </a>
                </Card>
            </div>
        </>);
}
