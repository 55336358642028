export function filterTransportListByState(transports, state) {
    switch (state) {
        case 'all':
            return transports;
        case 'pending':
            return transports.filter(transport => transport.status === 'new');
        case 'success':
            return transports.filter(transport => transport.status === 'fulfilled' || transport.status === 'transitCompleted');
        case 'warning':
            return transports.filter(transport => transport.status === 'planned' ||
                transport.status === 'inTransit' ||
                transport.status === 'assigned');
    }
}
