import React from 'react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ConstructionProjectStatus, updateConstructionProjectClientDetail, } from '../../api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import Toaster from '@/services/Toaster';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { constructionProjectByIdQuery, constructionProjectKeys, constructionProjectOrganizationOptionsQuery, } from '@/constructionProjects/queries';
import { ConstructionProjectForm, useConstructionProjectForm, } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectForm';
import { useAbility } from '@/reactBridge/useAbility';
export const ConstructionProjectClientEditForm = () => {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const { id } = useRouteParams();
    const queryClient = useQueryClient();
    const [constructionProjectQuery, { data: organizationOptions }] = useQueries({
        queries: [
            {
                ...constructionProjectByIdQuery(id),
                suspense: true,
                staleTime: Infinity,
                enabled: !!id,
            },
            {
                ...constructionProjectOrganizationOptionsQuery,
                suspense: true,
            },
        ],
    });
    // Fine to assert that it is not undefined due to suspense & query never disabled
    const constructionProjectInitialDetails = constructionProjectQuery.data;
    const { form } = useConstructionProjectForm({
        defaultValues: {
            salesforceProjectId: constructionProjectInitialDetails.salesforceProjectId,
            active: constructionProjectInitialDetails.status === ConstructionProjectStatus.Active,
            name: constructionProjectInitialDetails.name,
            description: constructionProjectInitialDetails.description,
            responsibleUserId: constructionProjectInitialDetails.responsibleUser.id,
            costCenter: constructionProjectInitialDetails.costCenter,
            validFrom: format(new Date(constructionProjectInitialDetails.validFrom), 'YYYY-MM-DD'),
            validTo: format(new Date(constructionProjectInitialDetails.validTo), 'YYYY-MM-DD'),
            teamMembers: Array.from(new Set(constructionProjectInitialDetails.teamMembers
                .map(member => member.id)
                .filter(id => id !== constructionProjectInitialDetails.responsibleUser.id))),
            color: constructionProjectInitialDetails.color ?? null,
            location: {
                address: {
                    formatted: null,
                    country: constructionProjectInitialDetails.country ?? null,
                    state: constructionProjectInitialDetails.state ?? null,
                    city: constructionProjectInitialDetails.city ?? undefined,
                    street: constructionProjectInitialDetails.street ?? null,
                    streetNumber: constructionProjectInitialDetails.addressNumber ?? null,
                    zipCode: constructionProjectInitialDetails.zip ?? undefined,
                    placeId: null,
                },
                coordinates: constructionProjectInitialDetails.geoCoordinate,
            },
            orderConfirmationMails: constructionProjectInitialDetails.orderConfirmationMails || [],
        },
        costCenterMandatory: organizationOptions?.organizationMetadata?.costCenterMandatory ?? false,
    });
    const { formState: { dirtyFields }, } = form;
    const mutation = useMutation({
        mutationFn: (data) => updateConstructionProjectClientDetail(id, data),
        onSuccess: () => {
            AnalyticsService.trackEvent('construction_project', {
                step: 'construction_project_edit',
                constructionProjectId: id,
                fieldsChanges: Object.keys(dirtyFields).join(', '),
                // Extra params for these 2 fields to allow easier filtering in analytics
                statusChanged: dirtyFields['active'] !== undefined,
                teamMembersChanged: dirtyFields['teamMembers'] !== undefined,
            });
            Toaster.success(t('pages.constructionProject.editForm.toaster.success'));
            return queryClient.invalidateQueries(constructionProjectKeys.namespace);
        },
        onError: (error) => {
            if (!error.response || error.response.status > 400) {
                Toaster.error(t('pages.constructionProject.editForm.toaster.unspecifiedServerError'));
            }
        },
    });
    const onSubmitConstructionProjectForm = (formData) => {
        const data = {
            salesforceProjectId: formData.salesforceProjectId,
            name: formData.name,
            description: formData.description,
            validFrom: new Date(formData.validFrom).toISOString(),
            validTo: new Date(formData.validTo).toISOString(),
            costCenter: formData.costCenter,
            color: formData.color ?? undefined,
            active: formData.active,
            bounds: [],
            responsibleUserId: formData.responsibleUserId,
            teamMembers: Array.from(new Set(formData.teamMembers)),
            city: formData.location.address.city,
            zip: formData.location.address.zipCode,
            street: formData.location.address.street ?? undefined,
            addressNumber: formData.location.address.streetNumber ?? undefined,
            orderConfirmationMails: formData.orderConfirmationMails,
            geoCoordinate: formData.location.coordinates,
        };
        mutation.mutate(data, {
            onSuccess: () => {
                vueRouter.back();
            },
            onError: (error) => {
                switch (error.response?.status) {
                    case 400: {
                        const fieldErrors = error.response?.data?.fieldErrors;
                        for (const [key, error] of Object.entries(fieldErrors)) {
                            form.setError(
                            // @ts-expect-error These are backend keys and are untypable
                            key, {
                                type: 'server',
                                message: error.message,
                            }, { shouldFocus: true });
                        }
                        break;
                    }
                }
            },
        });
    };
    const canManageConstructionProject = useAbility('createConstructionProject');
    return (<>
            {organizationOptions && (<ConstructionProjectForm form={form} organizationUsers={organizationOptions.users} organizationEmail={organizationOptions.organizationMetadata.email} submissionLoading={mutation.isLoading} onCancelClick={() => vueRouter.back()} onSubmit={onSubmitConstructionProjectForm} submitEnabled={canManageConstructionProject} submitButtonLabel={t('pages.constructionProject.editForm.saveButton.label')} cancelButtonLabel={t('pages.constructionProject.editForm.cancelButton.label')} costCenterMandatory={organizationOptions.organizationMetadata.costCenterMandatory}/>)}
        </>);
};
