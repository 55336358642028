import { platformInfoQuery } from '@/reactBridge/queryClient';
import { Combobox, TextField } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import { flag } from 'country-emoji';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from './Loader';
import countryCodes from '@/config/countryCodes';
import { parsePhoneNumber } from '../utils/phone';
const prefixes = countryCodes
    .map(countryCode => {
    const emoji = flag(countryCode.isoCode) ?? '❓';
    return {
        regionCode: countryCode.isoCode,
        value: countryCode.code,
        label: `${emoji} ${countryCode.code}`,
    };
})
    .toSorted((a, b) => a.value.localeCompare(b.value));
export function PhoneSelector(props) {
    const { t } = useTranslation();
    const platform = useQuery(platformInfoQuery);
    useEffect(() => {
        const code = platform.data?.platform.market.code;
        const prefix = prefixes.find(prefix => prefix.regionCode === code);
        if (!props.value?.countryCode && prefix) {
            props.onChange({ number: props.value?.number ?? '', countryCode: prefix.value });
        }
    }, [platform.data, props]);
    if (platform.isLoading)
        return <Loader />;
    return (<div className="flex">
            <Combobox value={props.value?.countryCode} onChange={countryCode => {
            if (countryCode) {
                props.onChange({ number: props.value?.number ?? '', countryCode });
            }
        }} options={prefixes} label={t('components.phoneFieldSet.prefix')} buttonClassName="rounded-r-none" className="-mr-px w-[8rem] rounded-r-none focus-within:z-10 hover:z-10"/>
            <TextField value={props.value?.number} onChange={e => {
            const parsed = parsePhoneNumber(e.target.value.trim().replace(/^00/, '+')); // Only parses phone numbers with prefix
            // If parse was successful, update the value with the parsed number, otherwise just update the national number
            props.onChange(parsed ?? { number: e.target.value, countryCode: props.value?.countryCode ?? '' });
        }} label="Telefonnummer" inputClassName="rounded-l-none" className="flex-1"/>
        </div>);
}
