import { MessageBox } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
function getPlatformActionTitleKey(action) {
    return `pages.organization.form.platformActions.${action}.title`;
}
function getPlatformActionDescriptionKey(action) {
    return `pages.organization.form.platformActions.${action}.description`;
}
function PlatformActionSection(props) {
    const { t } = useTranslation();
    return (<div className="flex flex-col gap-8 pt-16">
            <div className="font-copy-md">{t('pages.organization.form.platformActionsTitle')}</div>
            <ul className="flex flex-col gap-2">
                {props.allPlatformActionCodes.map(platformAction => {
            const hasPlatformAction = props.organizationPlatformActionCodes.includes(platformAction);
            return (<li key={platformAction}>
                            <MessageBox title={t(getPlatformActionTitleKey(platformAction))} type={hasPlatformAction ? 'success' : 'error'}>
                                {t(getPlatformActionDescriptionKey(platformAction))}
                            </MessageBox>
                        </li>);
        })}
            </ul>
        </div>);
}
export default PlatformActionSection;
