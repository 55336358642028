import { useTranslation } from 'react-i18next';
import { Card } from './Card';
export function ErrorCard(props) {
    const { t } = useTranslation();
    return (<>
            <div className="m-auto max-w-screen-md py-8">
                <Card title={t('pages.magic.components.errorCard.title')}>
                    <p>
                        {t('pages.magic.components.errorCard.text')}{' '}
                        <a className="underline" href="https://schuettflix.slack.com/archives/C021E4NB91S" target="_blank" rel="noopener noreferrer">
                            #ask-platform
                        </a>{' '}
                        {t('pages.magic.components.errorCard.or')}{' '}
                        <a className="underline" href="https://schuettflix.slack.com/archives/C03A47YKHQE" target="_blank" rel="noopener noreferrer">
                            #ask-salesforce
                        </a>
                    </p>
                    <p>{props.error}</p>
                </Card>
            </div>
        </>);
}
