import { objToArr, order } from '@/pages/Magic/utils/data';
import ApiService from '@/services/Api/ApiService';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';
export const orderSchema = z.number().positive().int();
export const featureSettingsSchemaBase = z.strictObject({
    title: z.string(),
    description: z.string(),
    label: z.string(),
});
export const featureSettingsSchema = z.discriminatedUnion('type', [
    featureSettingsSchemaBase.extend({ type: z.literal('bool'), default: z.boolean().optional() }),
    featureSettingsSchemaBase.extend({ type: z.literal('int'), default: z.number().int().optional() }),
    featureSettingsSchemaBase.extend({ type: z.literal('float'), default: z.number().optional() }),
]);
export const featureSchema = z.strictObject({
    title: z.string(),
    description: z.string(),
    mandatory: z.boolean(),
    requiredFeatures: z.string().array(),
    order: orderSchema,
    settings: z.record(z.string(), featureSettingsSchema),
});
export const organizationTypes = z.enum(['client', 'carrier', 'supplier', 'platform']);
export const definitionSchema = z.strictObject({
    globalFeatures: z.string().array(),
    organizationTypes: z.record(organizationTypes, z.strictObject({
        title: z.string(),
        headline: z.string(),
        description: z.string(),
        requiredTypes: organizationTypes.array(),
        incompatibleTypes: organizationTypes.array(),
        order: orderSchema,
        features: z.record(z.string(), featureSchema),
    })),
});
export function useDefinitions() {
    return useQuery({
        queryKey: ['organization', 'definition'],
        queryFn: async () => {
            const response = await ApiService.request({
                method: 'GET',
                url: `v3/organization/definition`,
            });
            return definitionSchema.parse(response.data);
        },
    });
}
// https://linear.app/schuettflix/issue/CASH-2140/reintroduce-organization-feature-templates
const HARDCODED_ADDITIONAL_MANDATORY_FEATURES = {
    client: ['client_project', 'client_delivery', 'client_phone_order'],
    carrier: ['carrier_delivery', 'carrier_shipment', 'carrier_capture_transport'],
    supplier: [],
    platform: [],
};
export function useDefinitionsForOrg(types) {
    const definitions = useDefinitions();
    // Convert data more to the view layer
    const sections = useMemo(() => definitions.data
        ? order(objToArr(definitions.data.organizationTypes).map(section => {
            return {
                ...section,
                isEnabled: types.includes(section.key),
                features: order(objToArr(section.features)).map(feature => ({
                    ...feature,
                    settings: objToArr(feature.settings),
                })),
            };
        }))
        : null, [definitions.data, types]);
    const getMandatoryFeaturesForType = useCallback((type) => {
        const mandatoryFeatures = sections
            ?.find(section => section.key === type)
            ?.features.filter(feature => {
            return feature.mandatory || HARDCODED_ADDITIONAL_MANDATORY_FEATURES[type].includes(feature.key);
        }) ?? [];
        const features = Object.fromEntries(mandatoryFeatures.map(feature => {
            const settings = Object.fromEntries(feature.settings.map(setting => [setting.key, setting.default ?? null]));
            return [feature.key, settings];
        }));
        return features;
    }, [sections]);
    return { sections, definitions, getMandatoryFeaturesForType };
}
