import { PAYMENT_METHODS } from '@/constants/paymentMethods';
import { platformInfoQuery } from '@/reactBridge/queryClient';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { Checkbox } from '@schuettflix/planum-react';
import { Button, Combobox, RadioButtonCard, TextField } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../components/Checkbox';
import { FieldError } from '../../../components/FieldError';
import { useSubForm } from '../form';
import { AccountingFields } from './common';
import { MandateReference } from './MandateReference';
import UnifiedAccounting from './UnifiedAccounting';
const EMAIL_INTERVAL_FAD = ['directly', 'never'];
const EMAIL_INTERVAL_DELIVERY_NOTES = ['directly', 'daily', 'weekly', 'biweekly', 'never'];
export function AccountingEdit(props) {
    const { t } = useTranslation();
    const { selectCreditNoteDocKey } = useLegalTerms();
    const form = useSubForm(AccountingFields, {
        defaultValues: props.defaultValue,
        mode: 'all',
    });
    const { register, formState: { errors }, control, handleSubmit, trigger, setValue, } = form;
    const paymentMethod = form.watch('paymentInfo.method');
    const mandateReference = form.watch('mandateReference');
    const platformInfo = useQuery(platformInfoQuery);
    const directDebitEnabled = platformInfo.data?.directDebitActive === true;
    const hasMandateReference = !!mandateReference?.id;
    const isMandateCheckboxDisabled = !directDebitEnabled || !!mandateReference?.acceptedAt || hasMandateReference;
    useEffect(() => {
        trigger();
    }, [trigger]);
    const emailDeliveryActive = form.watch('emailDeliveryNotesAttachedToReceipt');
    const emailSeparateFileForAttachments = form.watch('emailSeparateFileForAttachments');
    const emailReceiptAsZip = form.watch('emailReceiptAsZip');
    useEffect(() => {
        if (!emailDeliveryActive && emailSeparateFileForAttachments) {
            setValue('emailSeparateFileForAttachments', false);
        }
    });
    return (<form onSubmit={handleSubmit(data => props.onSave(data))}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col gap-6">
                <p className="font-copy-lg pt-16">{t('pages.organization.form.accountingInfo.headline')}</p>

                {/* BILLING */}
                <UnifiedAccounting isPlatformUser form={form}/>

                <div>
                    {/* BANK */}
                    <div>
                        <p className="font-copy-md-strong pb-2">{t('components.organizationForm.paymentInfo.title')}</p>
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.iban')} label={t('components.organizationForm.paymentInfo.iban')}/>
                                    <FieldError error={errors.paymentInfo?.iban}/>
                                </div>
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.bic')} label={t('components.organizationForm.paymentInfo.bic')}/>
                                    <FieldError error={errors.paymentInfo?.bic}/>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.name')} label={t('components.organizationForm.paymentInfo.name')}/>
                                    <FieldError error={errors.paymentInfo?.name}/>
                                </div>
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.owner')} label={t('components.organizationForm.paymentInfo.owner')}/>
                                    <FieldError error={errors.paymentInfo?.name}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <p className="font-copy-md-strong pb-2">
                        {t('components.organizationForm.paymentInfo.paymentMethod.title')}
                    </p>
                    <div className="flex gap-2">
                        <Controller control={control} name="paymentInfo.method" render={({ field }) => (<RadioButtonCard className="flex-1" {...field} inputValue={PAYMENT_METHODS.INVOICE} label={t('components.organizationForm.paymentInfo.paymentMethod.invoice.radioButtonTitle')}/>)}/>
                        <Controller control={control} name="paymentInfo.method" render={({ field }) => (<RadioButtonCard className="flex-1" {...field} inputValue={PAYMENT_METHODS.DIRECT_DEBIT} label={t('components.organizationForm.paymentInfo.paymentMethod.directDebit.radioButtonTitle')} disabled={!directDebitEnabled}/>)}/>
                    </div>
                </div>
                {directDebitEnabled && paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT && (<>
                        <Controller control={control} name="mandateReference.acceptedAt" render={({ field }) => (<Checkbox {...field} isDisabled={isMandateCheckboxDisabled} isSelected={!!field.value} onChange={() => {
                    field.onChange(new Date().toISOString());
                }} label={t('components.organizationForm.paymentInfo.mandateCheckbox.title')} description={t('components.organizationForm.paymentInfo.mandateCheckbox.text')}/>)}/>
                        {mandateReference?.id && (<MandateReference mandateReference={mandateReference}/>)}
                    </>)}

                {/* EMAIL */}
                <div>
                    <div className="font-copy-md-strong pb-6">
                        {t('pages.organization.view.headlines.emailPreferences')}
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="font-copy-md-strong">
                            {t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')}
                        </p>

                        <div>
                            <Controller control={control} name="invoiceEmailInterval" render={({ field }) => (<Combobox {...field} label={t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')} options={EMAIL_INTERVAL_FAD.map(i => ({
                value: i,
                label: t(`pages.organization.view.value.emailInterval.${i}`),
            }))} value={field.value}/>)}/>
                            <FieldError error={errors.invoiceEmailInterval}/>
                        </div>
                        <div>
                            <Controller control={control} name="invoiceRecipientEmails" render={({ field }) => {
            const emails = field.value ?? [];
            return (<Combobox {...field} multiple label={t('components.organizationForm.emailPreferences.email.label')} options={emails.map(email => ({ value: email, label: email }))} allowOptionAdding onAddOption={option => {
                    field.onChange([...emails, option]);
                }}/>);
        }}/>
                            <FieldError error={errors.invoiceRecipientEmails}/>
                        </div>

                        <div className="flex gap-4">
                            <div>
                                <Controller control={control} name="emailReceiptAsZip" render={({ field }) => (<CheckBox {...field} label={t('components.organizationForm.emailPreferences.emailReceiptAsZip')} disabled={emailSeparateFileForAttachments}/>)}/>
                                <FieldError error={errors.emailReceiptAsZip}/>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div>
                                <Controller control={control} name="emailDeliveryNotesAttachedToReceipt" render={({ field }) => (<CheckBox {...field} label={t(selectCreditNoteDocKey({
                creditNote: 'components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.creditNote',
                selfBilling: 'components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.selfBilling',
            }))}/>)}/>
                                <FieldError error={errors.emailDeliveryNotesAttachedToReceipt}/>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div>
                                <Controller control={control} name="emailSeparateFileForAttachments" render={({ field }) => (<CheckBox {...field} label={t('components.organizationForm.emailPreferences.separateFile')} disabled={emailReceiptAsZip || !emailDeliveryActive}/>)}/>
                                <FieldError error={errors.emailSeparateFileForAttachments}/>
                            </div>
                        </div>

                        <p className="font-copy-md-strong pt-2">
                            {t('components.organizationForm.emailPreferences.deliveryNote')}
                        </p>
                        <div>
                            <Controller control={control} name="deliveryNoteEmailInterval" render={({ field }) => (<Combobox {...field} label={t('components.organizationForm.emailPreferences.deliveryNoteSelectLabel')} options={EMAIL_INTERVAL_DELIVERY_NOTES.map(i => ({
                value: i,
                label: t(`pages.organization.view.value.emailInterval.${i}`),
            }))} value={field.value}/>)}/>
                            <FieldError error={errors.deliveryNoteEmailInterval}/>
                        </div>

                        <div>
                            <Controller control={control} name="deliveryNoteRecipientEmails" render={({ field }) => {
            const emails = field.value ?? [];
            return (<Combobox {...field} multiple label={t('components.organizationForm.emailPreferences.email.label')} options={emails.map(email => ({ value: email, label: email }))} allowOptionAdding onAddOption={option => {
                    field.onChange([...emails, option]);
                }}/>);
        }}/>
                            <FieldError error={errors.deliveryNoteRecipientEmails}/>
                        </div>

                        <div>
                            <Controller control={control} name="emailDeliveryNoteAsZip" render={({ field }) => (<CheckBox {...field} label={t('components.organizationForm.emailPreferences.emailDeliveryNoteAsZip')}/>)}/>
                            <FieldError error={errors.emailDeliveryNoteAsZip}/>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center gap-4 py-[40px]">
                    <Button type="reset" onClick={() => props.onCancel()} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                    <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                </div>
                <hr className="border-divider pb-16"/>
            </div>
        </form>);
}
