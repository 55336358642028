import { ALL_TRANSPORT_STATES, TRANSPORT_STATE_BY_STATUS, } from '@/pages/Order/TransportList/TransportTypes';
import { Select, Status, Date as DatePartial, DateRange } from '@schuettflix/react-components';
import { CancelIcon } from '@schuettflix/icons-react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { filterTransportListByState } from '@/pages/Order/TransportList/utils/filterTransportListByState';
import clsx from 'clsx';
export const TransportListMobile = ({ transports }) => {
    const { t } = useTranslation();
    const [currentState, setCurrentState] = useState('all');
    const transportList = filterTransportListByState(transports, currentState);
    const handleStatusChange = (state) => {
        if (!state)
            return;
        setCurrentState(state);
    };
    const handleSelectTransport = (transportId) => {
        window.location.href = `#/order-management/transport-hub/detail/${transportId}`;
    };
    const selectOptions = Object.values(ALL_TRANSPORT_STATES).map((state) => ({
        value: state,
        label: t(`order.transportList.status.${state}`),
    }));
    return (<div className="flex h-full flex-col items-center">
            <Select className="w-full p-1" value={currentState} onChange={handleStatusChange} options={selectOptions} label={t('order.transportList.state')}/>

            <div className="mt-4 flex h-full w-full flex-col justify-start gap-4 overflow-auto p-1">
                {transportList.map((transport) => (<TransportMobileCard key={transport.id} transport={transport} onSelectTransport={handleSelectTransport}/>))}
                {!transportList.length && (<div className="flex h-full flex-col items-center justify-center gap-4">
                        <CancelIcon size="xxl" disabled/>
                        <span className="font-copy-lg text-center text-subdued">
                            {t('order.transportList.status.emptyList')}
                        </span>
                    </div>)}
            </div>
        </div>);
};
const TransportMobileCard = ({ transport, onSelectTransport }) => {
    const { t, i18n } = useTranslation();
    const formatNumber = (value) => {
        return value.toLocaleString(i18n.resolvedLanguage);
    };
    return (<div key={transport.id} onClick={() => onSelectTransport?.(transport.id)} className="card flex min-h-[120px] cursor-pointer flex-col rounded p-0">
            <div className="flex h-2/3 flex-col justify-center gap-2 px-6 py-2">
                <div className="flex items-center justify-between">
                    <span className="font-copy-md-strong">{transport.transportNumber}</span>
                    <div className="font-copy-sm flex">
                        <Status status={TRANSPORT_STATE_BY_STATUS[transport.status]}/>
                        <span>{t(`order.transportList.status.${TRANSPORT_STATE_BY_STATUS[transport.status]}`)}</span>
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <span>
                        {transport.serviceDate?.type === 'period' && (<DateRange dateFrom={new Date(transport.serviceDate.from)} dateTo={new Date(transport.serviceDate.to)} locale={i18n.language} size="md"/>)}
                        {transport.serviceDate?.type === 'timePoint' && (<DatePartial date={new Date(transport.serviceDate.at)} locale={i18n.language} size="md"/>)}
                        {!transport.serviceDate && `---`}
                    </span>

                    <span className={clsx('whitespace-nowrap', {
            'font-bold text-success': transport.status === 'fulfilled',
        })}>
                        {formatNumber(transport.quantity)} {transport.unit}
                    </span>
                </div>
            </div>

            <div className="font-copy-sm flex justify-start gap-2 rounded-b bg-light-gray-200 px-6 py-[12px]">
                <span className="truncate">{transport.carrierName}</span>
                <span className="divide-rounded-sm">{transport.reference}</span>
            </div>
        </div>);
};
