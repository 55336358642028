import { asVueComponent } from '@/reactBridge/asVueComponent';
import { TransportListDesktop } from '@/pages/Order/TransportList/components/TransportListDesktop';
import { TransportListMobile } from '@/pages/Order/TransportList/components/TransportListMobile';
import { useTransportsScopedByOrder } from '@schuettflix/app-transport';
import { mapToTransportListItems } from '@/pages/Order/TransportList/utils/mapToTransportListItems';
import { useAppDimension } from '@/reactBridge/useAppDimension';
import { LoadingSpinner } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
export const _TransportList = ({ orderId }) => {
    const { t } = useTranslation();
    const { isDesktop } = useAppDimension();
    const { data, isLoading, isError } = useTransportsScopedByOrder({
        orderId,
    });
    if (isError) {
        return (<div className="font-copy-lg-strong mt-6 flex items-center justify-center gap-2 rounded-lg bg-red-600 p-6 text-on-critical">
                <span>{t('abstractresource.errors.serverError')}</span>
            </div>);
    }
    if (isLoading) {
        return (<div className="flex h-full items-center justify-center">
                <LoadingSpinner />
            </div>);
    }
    const transports = mapToTransportListItems(data);
    return isDesktop ? (<TransportListDesktop transports={transports}/>) : (<TransportListMobile transports={transports}/>);
};
export const TransportList = asVueComponent(_TransportList);
