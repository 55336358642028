import { useImageUpload } from '@/services/useClientFile';
import { ProductUseCaseImage } from '@schuettflix/client-file';
import { Avatar } from '@schuettflix/react-components';
import { useState } from 'react';
import { AvatarUploadEditor } from './AvatarUploadEditor';
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export function AvatarField(props) {
    const currentUrl = props.value &&
        'url' in props.value &&
        typeof props.value.url === 'object' &&
        props.value.url !== null &&
        'small' in props.value.url &&
        typeof props.value.url.small === 'string'
        ? props.value.url.small
        : null;
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const uploader = useImageUpload();
    return (<label className="cursor-pointer">
            <Avatar size="md" imageUrl={currentUrl ?? uploadedImageUrl}/>
            <input disabled={uploader.state.value === 'saving'} type="file" accept={ACCEPTED_IMAGE_TYPES.join(',')} className="sr-only" onChange={e => {
            if (e.target.files?.length === 1) {
                setSelectedImage(e.target.files[0]);
            }
        }} multiple={false}/>

            {selectedImage && (<AvatarUploadEditor image={selectedImage} onCancel={() => setSelectedImage(null)} onCrop={async (file) => {
                setSelectedImage(null);
                const uploaded = await uploader.uploadImage({
                    file,
                    meta: {
                        productUseCase: ProductUseCaseImage.ProfileImage,
                    },
                });
                if (uploaded) {
                    props.onChange(uploaded);
                    setUploadedImageUrl(uploaded.url.small ?? null);
                }
            }}/>)}
        </label>);
}
