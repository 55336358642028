import { useToaster } from '@/reactBridge/ToastProvider';
import { Button } from '@schuettflix/planum-react';
import { merge } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { useUserSave } from '../../hooks/api';
import { useOwnLocale } from '../../hooks/locales';
import { useEdit } from '../../hooks/useEdit';
import { getNextStep, MagicContext } from '../../utils/context';
import { BaseInformationEdit } from './BaseInformation/Edit';
import { BaseInformationPreview } from './BaseInformation/Preview';
import { UserMetadata } from './components/UserMetadata';
import { useUserForm } from './form';
import { useCheckExistingUser } from './hooks';
import { SettingsEdit } from './Settings/Edit';
import { SettingsPreview } from './Settings/Preview';
export function UserForm(props) {
    const { t } = useTranslation();
    const ctx = useContext(MagicContext);
    const form = useUserForm({ defaultValues: props.defaultValues });
    const ownLocale = useOwnLocale();
    const userSave = useUserSave();
    const toaster = useToaster();
    const [usedPrefill, setUsedPrefill] = useState(null);
    const organizationId = form.watch('organization.id');
    const isActive = form.watch('isActive');
    const permissions = form.watch('permissions');
    const locale = form.watch('locale');
    const id = form.watch('id');
    const salesforceContactId = form.watch('salesforceContactId');
    const check = useCheckExistingUser(id, salesforceContactId);
    // If a known id is passed, update the form with the current values
    useEffect(() => {
        if (check.user) {
            updateFromChild(check.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- it would cause an infinite loop
    }, [check.user]);
    // Prefill default values
    useEffect(() => {
        if (isActive === undefined) {
            form.setValue('isActive', true);
            form.trigger();
        }
    }, [form, isActive]);
    useEffect(() => {
        if (permissions === undefined) {
            form.setValue('permissions', []);
            form.trigger();
        }
    }, [form, permissions]);
    useEffect(() => {
        if (ownLocale && locale === undefined) {
            form.setValue('locale', ownLocale);
            form.trigger();
        }
    }, [form, ownLocale, locale]);
    const { edit, dispatch, isEditing } = useEdit({ baseInformation: false, settings: false });
    const disableSubmission = isEditing || userSave.isLoading || !form.formState.isValid;
    const updateFromChild = useCallback((data) => {
        // Update data and retrigger validation
        const newData = merge(form.getValues(), data);
        if (data.permissions)
            newData.permissions = data.permissions; // This needs to be overwritten manually, since the merge function will merge the arrays otherwise
        form.reset(newData);
        form.trigger();
    }, [form]);
    async function save(anotherUser) {
        if (!form.formState.isValid)
            return;
        try {
            const user = (await userSave.mutateAsync(form.getValues()));
            const newState = structuredClone(ctx.state);
            newState.refEntities.users.push(user);
            if (usedPrefill) {
                newState.prefilledUsers.push(usedPrefill);
            }
            toaster.success('Saved the user');
            if (anotherUser) {
                form.reset({});
            }
            else {
                newState.step = getNextStep(ctx.state);
            }
            ctx.dispatch(newState);
        }
        catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        ctx.dispatch({ title: 'User' });
        // eslint-disable-next-line react-hooks/exhaustive-deps -- onMount
    }, []);
    const buttons = organizationId ? (<>
            <Button type="button" onPress={() => save(true)} isDisabled={disableSubmission} prominence="secondary">
                {t('pages.magic.userForm.button.label.createMoreUsers')}
            </Button>
            <Button type="button" onPress={() => save(false)} isDisabled={disableSubmission}>
                {t('pages.magic.userForm.button.label.saveAndContinue')}
            </Button>
        </>) : (<Button type="button" onPress={() => {
            ctx.dispatch({ step: getNextStep(ctx.state) });
        }}>
            {t('pages.magic.userForm.button.skip')}
        </Button>);
    if (permissions === undefined) {
        return null;
    }
    return (<div className="flex flex-col py-16">
            {organizationId === undefined ? (<div className="mx-auto flex w-full max-w-screen-md flex-col">
                    <Card title={t('pages.magic.organization.card.title')}>
                        <UserMetadata onSelect={data => {
                if (data.prefillData?.data) {
                    // Save the prefilled, so it cannot be re-used later
                    setUsedPrefill(JSON.stringify(data.prefillData));
                    form.reset({ ...data.prefillData.data, organization: { id: data.organizationId } });
                }
                else {
                    form.setValue('organization.id', data.organizationId);
                }
                form.trigger();
            }}/>
                    </Card>
                </div>) : (<>
                    {edit.baseInformation ? (<BaseInformationEdit defaultValue={form.getValues()} onCancel={() => dispatch({ baseInformation: false })} onSave={data => {
                    dispatch({ baseInformation: false });
                    updateFromChild(data);
                }}/>) : (<BaseInformationPreview form={form} onEdit={() => dispatch({ baseInformation: true })}/>)}

                    {edit.settings ? (<SettingsEdit defaultValue={form.getValues()} onCancel={() => dispatch({ settings: false })} onSave={data => {
                    dispatch({ settings: false });
                    updateFromChild(data);
                }}/>) : (<SettingsPreview form={form} onEdit={() => dispatch({ settings: true })}/>)}
                </>)}

            {props.buttonSlot ? createPortal(buttons, props.buttonSlot) : buttons}
        </div>);
}
