export const BaseInformationFields = [
    'firstName',
    'lastName',
    'username',
    'mobile.countryCode',
    'mobile.number',
    'email',
    'locale',
    'isActive',
    'image.uuid',
];
