import { Button, DialogModal } from '@schuettflix/react-components';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
export function AvatarUploadEditor(props) {
    const { t } = useTranslation();
    const [imgUrl] = useState(URL.createObjectURL(props.image));
    const imgRef = useRef(null);
    const cropperRef = useRef(null);
    useEffect(() => {
        if (!imgRef.current)
            return;
        const cropper = new Cropper(imgRef.current, {
            aspectRatio: 1,
            autoCropArea: 1,
            viewMode: 1,
            movable: false,
            zoomable: false,
        });
        cropperRef.current = cropper;
        return () => {
            cropper.destroy();
        };
    }, []);
    const save = useCallback(() => {
        if (!cropperRef.current)
            return;
        cropperRef.current.getCroppedCanvas({ width: 512, height: 512 }).toBlob(blob => {
            if (blob) {
                const file = new File([blob], props.image.name, props.image);
                props.onCrop(file);
            }
        });
    }, [cropperRef.current]);
    return (<DialogModal title={t('components.avatarUpload.textButton')} onClose={props.onCancel} open={true}>
            <div className="flex flex-col gap-2">
                <img ref={imgRef} src={imgUrl}/>
                <div className="flex justify-end gap-2">
                    <Button label={t('components.avatarUpload.cancelButton')} variant="secondary" size="sm" onClick={props.onCancel}/>
                    <Button label={t('components.avatarUpload.saveButton')} size="sm" onClick={save}/>
                </div>
            </div>
        </DialogModal>);
}
